import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styling/GeorgiaStateREU.css'; // Assuming your CSS is in this file

const GeorgiaStateREU = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDetails = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="reu-container">
      <h2>Georgia State University Immersive Computing & IoT REU: June - July 2021</h2>
      <h3>Undergraduate Researcher</h3>
      <p>
        Developed fast 360-degree video compression based on user attention, using ML for IoT sensing.
        Learned technologies related to immersive computing, data science, & game programming under Dr. Zhisheng Yan of GSU.
      </p>
      <button className="details-button" onClick={toggleDetails}>
        {isExpanded ? 'Less Details' : 'More Details'}
      </button>
      {isExpanded && (
        <div className="details">
          <p>
            <strong>Project:</strong> Advanced 360 Video Compression via User Attention Metrics for IoT Camera Sensing<br />
            <strong>Institution:</strong> Georgia State University<br />
            <strong>Mentor:</strong> Dr. Zhisheng Yan<br />
            <strong>Duration:</strong> June - July 2021<br />
            <strong>Role:</strong> Lead Undergraduate Researcher<br />
            <strong>Project Details:</strong> <Link to="https://gsu-reu.netlify.app/reu2021_researchers">Explore GSU REU Researchers</Link><br />
            <strong>Competitive Edge:</strong> Selected from among the top 3.42% (10 out of 292)
          </p>
          <p>
            <strong>Introduction:</strong> I likely got selected because of the interesting work I was investigating at TARS on VR Drone Teleoperation. I always encourage people to do things that are out of the ordinary, it’s one way to stick out when you need to, all I do is follow my curiosity and it takes me pretty far. Out of 292 applicants, I, along with 9 others, was accepted into a very unique and fascinating undergraduate research program with a 3.42% acceptance rate that allowed me to learn about GPU programming, immersive computing (virtual reality ofc.), the “internet of things”, sophisticated computer vision techniques for image compression and image representation and feature extraction. Along with this REU, I was simultaneously taking an REU on Smart and Autonomous Internet-of-Things (IoT) Systems. You can probably tell I like doing two things at the same time… well, in this case, the professor I was initially supposed to work with wasn’t responding to my emails, so they had me on both REU’s simultaneously, worked out for me I suppose!
          </p>
          <p>
            Since this was the pandemic, I was virtually interfacing or zooming as a zoomer is wont to do with people- during the Georgia State University REU. So basically, I got to look outside the matrix. I decided to know the truth about our digital world, its infinite variety of images, so I took the red pill of image understanding, ah yes, the truth! Images were just matrices, after all.
          </p>
          <p>
            Ah yes, there’s nothing I would rather do that summer than learn about image compression. I remember the birds chirping, what do they sing? Oh they tell of course of the fascinating world of 360-degree video compression. Basically, when you look at an image, or a video, you only look at some things, other things don’t really make our brain that curious, they fail to capture our attention. Our algorithmic society often tries to harvest our attention through recommender systems. It's true! I read it in a book somewhere, maybe, maybe not. The shareholders of random company finds it most important that random citizen # 481,283 keep streaming Rick and Morty on Netflix, or… else!
          </p>
          <p>
            You can actually create models of this attention or saliency process, and you can map them onto videos, which are just sequences of images. My mentor Zhisheng Yan did just that, and created saliency map datasets <a href="https://github.com/phananh1010/PanoSaliency" target="_blank" rel="noopener noreferrer">here</a>. This allowed us to create a machine learning model to predict which chunks of an image should and shouldn’t be compressed. Why waste resources on data that nobody cares about? Besides, saving bandwidth is a huge deal for energy strapped 360 degree video sensors (like the cameras that capture footage of wildlife 24/7).
          </p>
          <p>
            You can find the project website <a href="https://gsu-reu.netlify.app/reu2021_researchers" target="_blank" rel="noopener noreferrer">here</a>
          </p>
          <p>
            <strong>Project Concept:</strong><br />
            <strong>Mentor:</strong> Dr. Zhisheng Yan (zyan@gsu.edu)<br />
            <strong>Title:</strong> 360 Video User Attention Detection through Spatial Audio<br />
            <strong>Description:</strong> 360 videos, namely VR videos, are large in terms of data amount. They have multiple viewports while a user only views one viewport at a time. There is a lot of redundant data that needs to be computed and transported in 360 videos. Identifying user attention in 360 videos has been an important research topic. By knowing which viewport user will be looking at, we can develop more efficient foveated rendering or streaming systems to improve user experience while saving computing and networking resources. In addition, understanding user attention can produce better 360 movies by focusing on content that users are more interested in.
          </p>
          <p>
            Head movement data and video content features have been used to identify user attention, namely visual saliency, in 360 videos. In this project, we focus on a completely new perspective by leveraging spatial audio data to infer visual saliency. We will collect the user's head movement data when viewing 360 videos that have spatial audio. We will utilize machine learning models to identify the relationship between the head movement and the spatial audio.
          </p>
          <p>
            <strong>References:</strong><br />
            <a href="https://pedro-morgado.github.io/spatialaudiogen/" target="_blank" rel="noopener noreferrer">A spatial audio dataset</a><br />
            <a href="https://zyan.gsucreate.org/papers/panosalnet_mm18.pdf" target="_blank" rel="noopener noreferrer">360 video user attention detection through video content features</a><br />
            <a href="https://github.com/phananh1010/PanoSaliency" target="_blank" rel="noopener noreferrer">Code for Saliency Maps</a><br />
            <a href="https://github.com/Caerii/fast360compression" target="_blank" rel="noopener noreferrer">Our code for blazing fast 360 degree video compression</a><br />
          </p>
          <p>
            <strong>Out of 292 applicants, Jakir was one of only 10 people selected for the program this summer.</strong><br />
            “I chose Clarkson University because I understood that it would be a space where I could have access to the opportunities that would allow me to explore my potential as a budding scientist. The most important thing that attracted me here was how easy it was for undergraduates to get involved with research,” Jakir said. Read more about it <a href="https://www.nny360.com/communitynews/education/clarkson-student-chosen-for-immersive-computing-research-experience-for-undergraduates/article_fa7d1aac-2b7a-553b-8aee-9713da326de5.html" target="_blank" rel="noopener noreferrer">here</a>.
          </p>
        </div>
      )}
    </div>
  );
};

export default GeorgiaStateREU;
