import React, { useRef, useEffect } from 'react';
import { useFrame, extend, useThree, useLoader } from '@react-three/fiber';
import { ShaderMaterial, TextureLoader } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as THREE from 'three';

extend({ OrbitControls });

const vertexShader = `
  uniform float uTime;
  varying vec2 vUv;

  float gaussian(float x, float sigma) {
    return exp(-0.5 * (x * x) / (sigma * sigma));
  }

  void main() {
    vUv = uv;

    vec3 newPosition = position;
    float distance = length(position.xy);
    float rippleEffect = sin(1000.0 * distance - uTime * 5.0) * gaussian(distance, 2000.0) * 0.2;
    newPosition += normal * rippleEffect * 0.3;

    gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
  }
`;

const fragmentShader = `
  uniform sampler2D uTexture;
  varying vec2 vUv;

  void main() {
    gl_FragColor = texture2D(uTexture, vUv);
  }
`;

const CMBRSphere = () => {
  const sphereRef = useRef();
  const controlsRef = useRef();
  const texture = useLoader(TextureLoader, '/CMB_12000px.jpg');
  const { camera, gl, scene } = useThree();
  const materialRef = useRef();

  useEffect(() => {
    // Setting up initial environment if needed
  }, [scene]);

  useFrame(({ clock }) => {
    const time = clock.getElapsedTime();
    if (materialRef.current) {
      materialRef.current.uniforms.uTime.value = time;
    }

    if (sphereRef.current) {
      sphereRef.current.rotation.y += 0.0003;
    }

    if (controlsRef.current) {
      controlsRef.current.update();
    }
  });

  return (
    <>
      <ambientLight intensity={0.2} />
      <directionalLight position={[10, 10, 10]} intensity={1} />
      <pointLight position={[-10, -10, -10]} intensity={1} />
      <mesh ref={sphereRef}>
        <sphereGeometry args={[4.5, 64, 64]} />
        <shaderMaterial
          ref={materialRef}
          uniforms={{
            uTime: { value: 0 },
            uTexture: { value: texture },
          }}
          vertexShader={vertexShader}
          fragmentShader={fragmentShader}
        />
      </mesh>
      <orbitControls ref={controlsRef} args={[camera, gl.domElement]} enableZoom={true} enablePan={true} enableRotate={true} enableDamping={true} dampingFactor={0.01} />
    </>
  );
};

const CMBCanvas = () => {
  return (
      <CMBRSphere />
  );
};

export default CMBCanvas;
