import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styling/VizcomAI.css'; // Assuming your CSS is in this file

const VizcomAI = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDetails = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="vizcom-container">
      <h2>Vizcom.AI: June - August 2022 (Machine Learning Intern)</h2>
      <h3>Machine Learning Intern</h3>
      <p>
        Collaborated with ML Engineers to develop a 2D-to-3D pipeline for Generative 3D AI, including model training, testing, and implementation of advanced 3D synthesis techniques from research papers using PyTorch. Learned about machine learning model monitoring with Weights & Biases and ML workflows for cloud-based development environments.
      </p>
      <button className="details-button" onClick={toggleDetails}>
        {isExpanded ? 'Less Details' : 'More Details'}
      </button>
      {isExpanded && (
        <div className="details">
          <p>
            Vizcom basically allows designers to condition generated images on their sketches, so you can sketch, and have a full render based on that sketch, and a prompt. Like so:
          </p>
          <p>
            <strong>Sketch:</strong>
          </p>
          <p>
            <strong>Image:</strong>
          </p>
          <p>
            It was absolutely fascinating technology at the time, and it’s only getting better today. I was working on trying to implement an early version of pix2pix3D, which essentially takes as input a sketch, which then generates an image (like this workflow here <a href="https://github.com/junyanz/pytorch-CycleGAN-and-pix2pix" target="_blank" rel="noopener noreferrer">pix2pix workflow</a>), and then a 3D representation from the generated image. We were not entirely successful as there was a lot of trial and error, while 3D objects were generated, they were very crude and were very warped, different hyperparameter combinations had a massive impact on the generated 3D models, but ultimately the results looked quite ugly. The current iteration of Vizcom’s 3D synthesis is pretty good for first pass sketching, and I’m sure it will get a lot better over time.
          </p>
          <p>
            Here is a modern-day implementation of sketch to 3D synthesis: <a href="https://www.cs.cmu.edu/~pix2pix3D/" target="_blank" rel="noopener noreferrer">CMU pix2pix3D</a> and <a href="https://github.com/dunbar12138/pix2pix3D" target="_blank" rel="noopener noreferrer">GitHub pix2pix3D</a>
          </p>
          <p>
            I learned about how to monitor machine learning models using Weights and Biases, and how to use containerized deployments for virtual development environments. It was a great learning experience and definitely provided a hands-on way for me to understand some of the intricacies in the actual developer world. At the same time that I was doing this, I was also working on Calculus 3, a class that I was taking remotely for summer credit, which was fun to learn as I was doing this other stuff.
          </p>
          <p>
            I also got to hang out with some friends, went to San Francisco and San Jose, biked around Google (the omnipresent surveillance generator of Capitalism), and spent wayyy too much money on food. I also met OpenBCI, here’s a picture of me with them:
          </p>
          <p>
            <strong>Me with OpenBCI</strong>
          </p>
          <p>
            They won the Auggie Awards at AWE XR for their design of a wearable mixed reality brain-computer interface, really the first of its kind in the world- called the Galea.
          </p>
          <p>
            I really wanted to work with OpenBCI at the time, and might work with them in the future at some point. I think wearable hyper-personalized computing systems will be tightly integrated into our peripheral wearables. At the very least, it will be an option. I would love to control extra robotic limbs on my back using a brain-computer interface, hopefully these systems get sophisticated enough to allow that!
          </p>
        </div>
      )}
    </div>
  );
};

export default VizcomAI;
