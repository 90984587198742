import React, { useRef, useEffect, useState } from 'react';
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber';
import { OrbitControls, Html } from '@react-three/drei';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { EffectComposer, SSAO } from '@react-three/postprocessing';
import * as THREE from 'three';

const generateVertexShader = (type) => `
  uniform float u_time;
  varying vec2 vUv;

  void main() {
    vUv = uv;
    vec3 pos = position;

    ${type === 'sin' ? 'pos.z += sin(pos.x * 10.0 + u_time * 5.0) * 0.4;' : ''}
    ${type === 'cos' ? 'pos.y += cos(pos.y * 5.0 + u_time * 9.0) * 0.4;' : ''}
    ${type === 'swag' ? 'pos.y += sin(cos(pos.z * 3.0 + u_time * 8.5) * 1.4);' : ''}
    ${type === 'mix' ? `
      pos.z += sin(pos.x * 10.0 + u_time * 5.0) * 0.1;
      pos.y += cos(pos.y * 5.0 + u_time * 3.0) * 0.1;
      pos.x += cos(pos.z * 3.0 + u_time * 0.5) * 0.4;
    ` : ''}
    ${type === 'warp' ? `
      pos.x += sin(pos.z * 2.0 + u_time * 2.0) * 0.2;
      pos.y += cos(pos.x * 2.0 + u_time * 3.0) * 0.2;
      pos.z += sin(pos.y * 2.0 + u_time * 4.0) * 0.2;
    ` : ''}
    ${type === 'twist' ? `
      float angle = sin(u_time + pos.y * 3.0) * 1.0;
      pos.x = cos(angle) * position.x - sin(angle) * position.z;
      pos.z = sin(angle) * position.x + cos(angle) * position.z;
    ` : ''}
    ${type === 'pulse' ? 'pos *= 1.0 + 0.3 * sin(u_time * 5.0);' : ''}
    ${type === 'wave' ? 'pos.y += sin(pos.x * 3.0 + u_time * 5.0) * 0.5;' : ''}
    ${type === 'spiral' ? 'pos.xy *= mat2(cos(u_time), -sin(u_time), sin(u_time), cos(u_time));' : ''}
    ${type === 'ripple' ? 'pos.z += sin(length(pos.xy) * 10.0 - u_time * 5.0) * 0.2;' : ''}
    ${type === 'explode' ? 'pos *= 1.0 + 0.2 * sin(length(pos) * 10.0 - u_time * 5.0);' : ''}
    ${type === 'breathe' ? 'pos *= 1.0 + 0.2 * sin(u_time * 3.0);' : ''}
    ${type === 'shiver' ? 'pos.xy += 0.1 * sin(pos.z * 10.0 + u_time * 20.0);' : ''}
    ${type === 'bounce' ? 'pos.y += abs(sin(pos.x * 3.0 + u_time * 5.0)) * 0.3;' : ''}
    ${type === 'squash' ? 'pos.z *= abs(sin(u_time * 2.0)) + 0.5;' : ''}
    ${type === 'twitch' ? 'pos.xy += 0.05 * sin(u_time * 50.0);' : ''}
    ${type === 'morph' ? 'pos.xy += sin(u_time * 2.0 + pos.yx * 5.0) * 0.2;' : ''}
    ${type === 'sizzle' ? 'pos.z += tan(pos.x * 5.0 + u_time * 2.0) * 0.2;' : ''}
    ${type === 'jitter' ? 'pos.xy += sin(u_time * 10.0 + pos.yx * 10.0) * 0.1;' : ''}
    ${type === 'fizz' ? 'pos.z += sin(pos.x * 3.0 + u_time * 5.0) * cos(pos.y * 3.0 + u_time * 5.0) * 0.3;' : ''}
    ${type === 'warp2' ? 'pos.xy *= mat2(cos(u_time * 0.5), -sin(u_time * 0.5), sin(u_time * 0.5), cos(u_time * 0.5));' : ''}
    ${type === 'glitch' ? 'pos.xy += sin(pos.z * 10.0 + u_time * 20.0) * 0.05;' : ''}
    ${type === 'distort' ? 'pos *= 1.0 + 0.2 * sin(pos * 10.0 + u_time * 5.0);' : ''}
    ${type === 'meltdown' ? 'pos.y += tan(u_time + pos.y * 5.0) * 0.1;' : ''}
    ${type === 'drip' ? 'pos.xy += 0.05 * sin(pos.yx * 10.0 + u_time * 5.0);' : ''}
    ${type === 'squiggle' ? 'pos.z += sin(pos.x * 10.0 + u_time * 3.0) * 0.3;' : ''}
    ${type === 'bubble' ? 'pos *= 1.0 + 0.1 * cos(u_time * 3.0 + length(pos) * 5.0);' : ''}
    
    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
  }
`;

const fragmentShader = `
  uniform float u_time;
  uniform vec3 u_color;
  varying vec2 vUv;

  void main() {
    vec3 color = u_color;
    color += 0.2 * sin(20.0 * vUv.x + u_time * 5.0);
    color += 0.2 * cos(20.0 * vUv.y + u_time * 5.0);
    gl_FragColor = vec4(color, 1.0);
  }
`;

const colors = [
  new THREE.Color(0xFFD700), // Gold
  new THREE.Color(0xFFC0C0), // Silver
  new THREE.Color(0xFF0032), // Bronze
];

function RotatingCoin({ text, link, colorIndex }) {
  const coinRef = useRef();
  const { camera, gl } = useThree();
  const font = useLoader(FontLoader, 'https://threejs.org/examples/fonts/helvetiker_regular.typeface.json');
  const [hovered, setHovered] = useState(false);
  const modulationType = [
    'sin', 'cos', 'swag', 'mix', 'warp', 'twist', 
    'pulse', 'wave', 'spiral', 'ripple', 'explode', 
    'breathe', 'shiver', 'bounce', 'squash', 'twitch', 
    'morph', 'sizzle', 'jitter', 'fizz', 'warp2', 
    'glitch', 'distort', 'meltdown', 'drip', 
    'squiggle', 'bubble'
  ][Math.floor(Math.random() * 27)];

  const [shaderMaterial] = useState(() =>
    new THREE.ShaderMaterial({
      uniforms: {
        u_time: { value: 0 },
        u_color: { value: colors[colorIndex % colors.length] },
      },
      vertexShader: generateVertexShader(modulationType),
      fragmentShader,
      side: THREE.DoubleSide,
    })
  );

  useEffect(() => {
    if (coinRef.current) {
      const coinMesh = coinRef.current.children[0];
      const textMesh = coinRef.current.children[1];
      if (coinMesh) {
        coinMesh.material = shaderMaterial;
      }
      if (textMesh) {
        textMesh.material = new THREE.MeshStandardMaterial({
          color: new THREE.Color(0x000),
        });
      }
    }
  }, [coinRef.current, shaderMaterial]);

  useFrame((state, delta) => {
    shaderMaterial.uniforms.u_time.value += delta;
    if (coinRef.current) {
      coinRef.current.rotation.y += 0.01;
    }
  });

  return (
    <>
      <ambientLight intensity={1.5} />
      <directionalLight position={[0, 10, 15]} intensity={2.5} />
      <pointLight position={[0, 10, -10]} intensity={2.0} />
      <group ref={coinRef} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
        <mesh>
          <torusGeometry args={[0.8, 0.2, 16, 100]} /> 
        </mesh>
      </group>
      <Html position={[0, -1.5, 0]} center>
        <a href={link} className="coin-label" style={{ color: hovered ? 'lightblue' : 'gray', userSelect: 'none' }}>{text}</a>
      </Html>
      <OrbitControls args={[camera, gl.domElement]} enableZoom={true} />
      <EffectComposer>
        <SSAO intensity={1} radius={0.1} />
      </EffectComposer>
    </>
  );
}

export default RotatingCoin;
