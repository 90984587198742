import React, { useEffect, useRef } from 'react';
import { Timeline } from 'vis-timeline/standalone';
import 'vis-timeline/styles/vis-timeline-graph2d.min.css';
import './styling/Timeline.css';

const MyTimeline = () => {
    const timelineRef = useRef(null);

    useEffect(() => {
        if (timelineRef.current) {
            const items = [
                {id: 1, content: 'MIT Media Lab, Fluid Interfaces', start: '2022-09-01', end: new Date(), title: 'Developing touchless holographic interfaces using EEG.'},
                {id: 2, content: 'Holosonic', start: '2023-09-01', end: new Date(), title: 'Co-leading novel engineering solutions in Synthetic Biology.'},
                {id: 3, content: 'Vizcom.ai', start: '2022-06-01', end: '2022-08-31', title: 'Developed a 2D to 3D pipeline for Generative 3D AI.'},
                {id: 4, content: 'NASA RASC-AL', start: '2021-09-01', end: '2022-05-31', title: 'Led Lunar South Pole mission design.'},
                {id: 5, content: 'Halcyox', start: '2021-06-01', end: new Date(), title: 'Founder & CEO driving AI learning augmentation.'},
                // Add more items as needed
            ];

            const options = {
                start: '2021-01-01',
                end: new Date(), // dynamically set to today's date
                editable: false, // set to true if you want items to be draggable
                margin: {
                    item: 20, // minimum margin between items
                },
                tooltip: {
                    followMouse: true,
                    overflowMethod: 'cap',
                },
                orientation: 'top',
            };

            // Create a timeline
            const timeline = new Timeline(timelineRef.current, items, options);

            return () => {
                timeline.destroy();
            };
        }
    }, []);

    return <div ref={timelineRef} style={{height: '400px'}} />;
};

export default MyTimeline;
