import React from 'react';
import './styling/Bio.css'; // Make sure this is the correct path to your CSS file

const Bio = () => (
  <div className="bio-container">
    <h2>DO NOT READ THIS SENTENCE.</h2>
    <p className="intro">
      <p>Hello, I'm Alif Jakir. 
      I am an avante-garde AI Researcher, Creative Technologist, Roboticist, and Transdisciplinary Philosopher-Scientist.</p>

      <p>I love creating artwork and telling stories.</p>

      <p>I received two B.S. Degrees from Clarkson University in 2023. One in Computer Science, 
      the other in Business Intelligence and Data Analytics. I have minors in Mathematics and Robotics.</p>

      <p>During my undergraduate studies I researched under the advisory of 
      <a href="https://docs.google.com/document/d/1Ee6Be9gR2qmjGu9X8PPQJYi2Wnwnv7BPE8-jgFFeJUo/edit#heading=h.6oh52zfpd5qt"> Sean Banerjee </a>
       and <a href="https://docs.google.com/document/d/1DHxngxY1ZuI3Rcvfc8cJRhR-4Qflo7yMpdjomYNDfsU/edit#heading=h.16bfkocay0m3"> Natasha Banerjee</a>'s HCI Lab 
        <a href="https://tars.clarkson.edu/"> TARS</a>.</p>

      <p>During my tenure at the lab, I investigated VR Teleoperation, Modular Drone Robotics, 
        computer graphics, human robot collaboration, motion-based biometrics,
        integrating AI into virtual reality systems.</p>

      <p>
        I am deeply interested in the symbiosis future
      </p>

      <p>I am fundamentally a <i><b>systems thinker</b></i>.</p>

      I am interested in the future of civilization,
      polymathy, evolution, h4ckerz, SWAG, startups, open-source, ummmm yeah!!!

      <p>New Renaissance of human civilization,
      and I am excited to be a part of it!</p>

      <p>I enjoy the philosophical perspective of a Martian Philosopher from the 23rd century, examining Earth
      and I love breaking down the complexities of the world.</p>

      <p>I want to destroy silos in academia, industry, and society.</p>

      I think the best way to explain ones self is to show you what I can do.

      I am actively learning about: computational neuroscience

      I am interested in the fundamentals behind complex system dynamics

      I am considering a PhD in AI, Robotics, or Cognitive Science.

    </p>

    {/* <section className="projects"> 
      <h3>Recent News & Talks</h3>
      <ul>
        <li><a href="https:">Stuff</a> - </li>
      </ul>
    </section> */}

    {/* <section className="projects"> 
      <h3>Research and Publications</h3>
    </section> */}


    <section className="projects"> 
      <h3>My Projects</h3>
      <ul>
        <li><a href="https://artificialgeneralintelligence.dev">ArtificialGeneralIntelligence.dev</a> - Opinionated AGI resources for developers like myself.</li>
        <li><a href="https://checkmysoundcloud.com">CheckMySoundcloud.com</a> -  This one is ostensibly, a meme, and a love letter to old internet culture.</li>
        {/* <li><a href="https://sites.mit.edu/magic">MIT AGI Consortium</a> - I direct MIT's student-led organization driving experimental AGI research, discussion, and collaborative development.
        Ultimately, my goal is to develop a supportive environment at MIT much better connections to industry resources, centering around AGI.</li> 
        
         */}
        <li><a href="https://singularity.computer">Singularity.computer</a> - Investigating the technological singularity and its impact.
        This site goes through the history of the entire Universe, the future, and has options to make the web-page you are on self-aware.</li> 

        {/* selfawareweb.site - This is a project I am working on that is not yet live. It is a web-page that is self-aware.
        It utilizes the local computer through WebGPU to start commenting on its own existence in a meta and humorous way. */}
      </ul>
    </section>

    <section className="projects"> 

     <h3>My Companies</h3>
     <ul>
        <li><a href="https://halcyox.com">Halcyox</a> - 
          "Democratizing Superintelligence", how do we build more critical systems thinking? 
          Can we create Superintelligent Human Machine Teams? 
          How can we augment human reasoning in a way that can make us wiser? 
          We're currently investigating autonomous agents for augmenting
          enterprises of all scales. 
          
          My company <i>Halcyox</i> is supported by the NVIDIA Inception startup accelerator.
        </li>

        <li><a href="https://www.holosonic.org/">Holosonic</a> - Building a programmable volumetric 
        ultrasonic levitator for synthetic biology. I think about it as a touchless way to assemble living matter.</li> 
     
        <li><a href="https://www.visuaml.com/">VisuaML</a> - A visual drag and drop interface for AI architectures, with the ultimate goal
        of making machine learning more collaborative.
        we are working on developing a pathway for scalable co-design of hardware and software</li> 
     </ul>    

    </section>



    <section class="expertise">
    <h2>My Expertise</h2>
    <div class="expertise-grid"> 
        <div>
            <h3>Core Focus</h3>
            <p>Machine Learning<br/>(Deep Learning, NLP, Computer Vision)</p>
        </div>
        <div>
            <h3>Building the Future</h3>
            <p>Cognitive Architecture<br/>Neural Information Processing</p>
        </div>
        <div>
            <h3>Technical Skills</h3>
            <p>Full-Stack Development<br/>Cloud Technologies</p>
        </div>
        <div>
            <h3>Interests</h3>
            <p>Robotics<br/>Cybernetics<br/>Artificial General Intelligence</p>
        </div>
    </div>
    <p class="expertise-summary">
        Driven to create sophisticated AI systems that mirror the complexity of the human mind, 
        while contributing to the advancement of human civilization through technology.
    </p>
</section>
  </div>
);

export default Bio;
