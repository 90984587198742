import { useRef, useEffect } from 'react';
import { useLoader, useFrame, extend, useThree } from 'react-three-fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as THREE from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';

extend({ OrbitControls });

function RotatingBrain({ modelPath }) {
  const brainRef = useRef();
  const lightRef = useRef();
  const controlsRef = useRef();
  const gltf = useLoader(GLTFLoader, modelPath);
  const { camera, gl, scene } = useThree();

  useEffect(() => {
    new RGBELoader().load('/path/to/your/hdr/image.hdr', (texture) => {
      texture.mapping = THREE.EquirectangularReflectionMapping;
      scene.environment = texture;
      scene.background = texture;
    });

    if (gltf.scene) {
      gltf.scene.traverse((child) => {
        if (child.isMesh) {
          child.material = new THREE.MeshPhysicalMaterial({
            transmission: 0.95,
            roughness: 0.1,
            metalness: 0.9,
            clearcoat: 1.0,
            clearcoatRoughness: 0.05,
            reflectivity: 0.9,
            ior: .91,
            thickness: 0.9,
            envMapIntensity: 1,
            side: THREE.DoubleSide,
            color: new THREE.Color(0xFFC0CB),
          });
          child.material.needsUpdate = true;
        }
      });
    }
  }, [gltf.scene, scene]);

  useFrame(({ clock }) => {
    const time = clock.getElapsedTime();
    const lightIntensity = (Math.sin(time) * .2 + 1) / 2 * 0.5 + 0.01;
    if (lightRef.current) {
      lightRef.current.intensity = lightIntensity*time;
    }


    if (brainRef.current) {
      brainRef.current.rotation.y -= 0.003;
    }

    if (controlsRef.current) {
      controlsRef.current.update();
    }
  });

  const brainSize = 50;

  return (
    <>
      <ambientLight intensity={0.4} />
      <directionalLight position={[0, 10, 15]} intensity={1} />
      <primitive position={[0, -4, 0]} object={gltf.scene} scale={[brainSize, brainSize, brainSize]} ref={brainRef} />
      <orbitControls ref={controlsRef} args={[camera, gl.domElement]} enableZoom={true} enablePan={true} enableRotate={true} enableDamping={true} dampingFactor={0.01} />
    </>
  );
}

export default RotatingBrain;
