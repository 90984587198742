// App.js
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage'; // Make sure this path is correct
import BlogPost from './components/BlogPost'; // Assuming you have this component ready
import Portfolio from './components/Portfolio1'; // Import the Portfolio component

function App() {
  // Define your blog post data
  const blogPosts = [
    {
      title: 'How to Create a Virtual Universe',
      description: 'Exploring the fundamentals of building a virtual universe, from physics simulations to creating immersive environments.',
      link: '/blog/post-1',
      content: `
        Building a virtual universe requires a deep understanding of both technology and creativity. 
        It starts with simulating physical laws to create believable environments. 
        One must consider gravity, electromagnetism, and even quantum mechanics. 
        Beyond physics, creating rich, immersive environments involves detailed modeling, texturing, and the use of sophisticated graphics engines.
        Moreover, integrating AI-driven characters and ecosystems can bring these virtual worlds to life, making them dynamic and interactive.
        This blog post will delve into the tools, techniques, and theories behind virtual universe creation, providing a comprehensive guide for aspiring digital creators.
      `,
      image: 'src/blog/images/virtual_universe.jpg',
    },
    {
      title: 'Consciousness as Epiphenomena',
      description: 'A deep dive into the philosophical perspective that consciousness arises as a secondary effect of complex neural processes.',
      link: '/blog/post-2',
      content: `
        The concept of consciousness as an epiphenomenon posits that conscious experiences are by-products of neural activity, rather than causative agents.
        This perspective raises intriguing questions about the nature of self-awareness and free will.
        By examining the latest neuroscientific research, we explore how complex interactions within the brain give rise to subjective experiences.
        Philosophers like Thomas Huxley and modern cognitive scientists provide insights into how consciousness, though not influencing physical processes, plays a crucial role in our perception of reality.
        This post navigates through these theories, offering a nuanced understanding of the mind's enigmatic nature.
      `,
      image: 'src/blog/images/consciousness_epiphenomena.jpg',
    },
    {
      title: 'Deals with Superintelligence',
      description: 'Understanding the potential implications and ethical considerations of creating superintelligent AI systems.',
      link: '/blog/post-3',
      content: `
        Superintelligence refers to AI systems that surpass human intelligence in all domains. 
        The creation of such entities poses significant ethical and existential questions. 
        How do we ensure that superintelligent AIs align with human values and goals? 
        This blog post examines scenarios where superintelligence could benefit humanity, such as solving complex global issues, while also addressing the risks, such as loss of control and unintended consequences.
        By reviewing contemporary discussions from AI theorists like Nick Bostrom and practical frameworks like AI alignment, we aim to provide a balanced view on navigating the future with superintelligent agents.
      `,
      image: 'src/blog/images/superintelligence.jpg',
    },
    {
      title: 'The Future of Work',
      description: 'Analyzing how emerging technologies and AI are reshaping the landscape of work and employment.',
      link: '/blog/post-4',
      content: `
        The future of work is being fundamentally transformed by advances in AI, robotics, and digital technologies.
        Automation is shifting job roles, leading to the creation of new career paths while rendering some traditional ones obsolete. 
        This transformation prompts a rethinking of education and skill development to prepare for a dynamic job market.
        Additionally, the rise of remote work and digital nomadism is redefining workplace culture and collaboration.
        This blog post explores these trends, offering insights into how individuals and organizations can adapt to thrive in a rapidly evolving economic landscape.
      `,
      image: './src/blog_images/future_of_work.jpg',
    },
  ];

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage blogPosts={blogPosts} />} />
        {blogPosts.map((post, index) => (
          <Route
            key={index}
            path={post.link}
            element={<BlogPost title={post.title} content={post.content} image={post.image} />}
          />
        ))}
        <Route path="/portfolio" element={<Portfolio />} />
      </Routes>
    </Router>
  );
}

export default App;
