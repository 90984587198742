// HomePage.js
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Canvas } from '@react-three/fiber';
import { Link } from 'react-router-dom';

import ThreeDBlogPostTile from './ThreeDBlogPostTile';
import RotatingBrain from './RotatingBrain';
import StillLife from './test-components/StillLife';
import AlifCape from './test-components/AlifCape';
import RotatingCoin from './RotatingCoin';
import CMBCanvas from './CMBRSphere';

import profileImage from './profile-image.jpg';
import Bio from './Bio';
import Contact from './Contact';

import './styling/HomePage.css';
import './styling/Header.css';
import './styling/Contact.css';
import './styling/Bio.css';
import './styling/Footer.css';
import './styling/Coin.css';

function HomePage({ blogPosts }) {
  const username = 'Alif Jakir';

  return (
    <div className="App">
      <header className="header">
        <Container className="main-container">
          <Row>
            <Col md={12} className="profile">
              <div className="model-container">
                <Canvas className="react-canvas" camera={{ position: [25, 6, 40], fov: 40 }}>
                  <RotatingBrain modelPath="/Brain.glb" />

                </Canvas>
                {/* <Canvas className="react-canvas" camera={{ position: [0, -15, 25], fov: 30 }}>

                <StillLife modelPath="/StillLife.glb" />

                  <AlifCape modelPath="/Alif/alifcapecolor.fbx" scale={.6}/>

                </Canvas> */}

                {/* <Canvas className="react-canvas" camera={{ position: [25, -3, 40], fov: 40 }}>
                  <StillLife modelPath="/StillLife.glb" />
                </Canvas> */}

                {/* <Canvas className="react-canvas" camera={{ position: [35, 5, 40], fov: 10 }}>
                  <CMBCanvas />
                </Canvas> */}
                
              </div>

              <Link to="/">
                <h1>{username}</h1>
              </Link>

              <p>Transdisciplinary Philosopher-Scientist & Generalist AI Engineer</p>

              <Contact />

              <Link to="/portfolio">
                <Button>View Portfolio</Button>
              </Link>

              <Bio />
            </Col>
          </Row>
        </Container>
      </header>
      
      <main className="blog-content">
        {blogPosts.map((post, index) => (
          <div key={index} className="blog-post">
            <h3 className="blog-post-title">{post.title}</h3>
            <p className="blog-post-description">{post.description}</p>
            <Canvas>
              <ThreeDBlogPostTile
                title={post.title}
                description={post.description}
                link={post.link}
              />
            </Canvas>
          </div>
        ))}
      </main>


      <footer className="footer">
        <Container className="main-container">
          <p>&copy; {new Date().getFullYear()} Alif Jakir. All rights reserved.</p>
        </Container>
      </footer>
    </div>
  );
}

export default HomePage;
