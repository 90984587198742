// TerascaleResearch.js
// This is the portfolio item for Terascale All-Sensing Research Studio (TARS) at Clarkson University.

import React, { useState } from 'react';
import './styling/TerascaleResearch.css';
// Import rotating brain
import RotatingBrain from '../RotatingBrain';
import VRHeadset from '../VRHeadset';
// import Canvas from react three fiber
import { Canvas } from '@react-three/fiber';

const TerascaleResearch = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDetails = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="terascale-research">
      <div className="summary">
        
        <h2><a href="https://tars-home.github.io/">Terascale All-Sensing Research Studio (TARS)</a>: Fall 2020 - May 2023 (Undergraduate Researcher)</h2>
        <h3><i>Summary</i></h3>
        
        <p>I led a diverse team in creating biometric ID for drone pilots using VR teleoperation control systems. Essentially, from our designed modular drones that we made using 3D printed and off-the-shelf parts, we developed neural network-based ID techniques using full-body movement data, under Dr. Sean Banerjee’s guidance.</p>
        <button className="details-button" onClick={toggleDetails}>
          {isOpen ? 'Less Details' : 'More Details'}
        </button>
      </div>

        <div className="model-container-tars">
            <Canvas className="react-canvas-tars" camera={{ position: [25, 6, 40], fov: 40 }}>
                <VRHeadset modelPath="/Quest3.glb" />
            </Canvas>
        </div>

 
      {isOpen && (

        
        // Insert 3D spinning modular drone components here
        // Insert Animations and pictures
        // Put more links for the process of the project

        <div className="details">
            <div className="model-container-tars">
                <Canvas className="react-canvas-tars" camera={{ position: [25, 6, 40], fov: 40 }}>
                  <RotatingBrain modelPath="/Brain.glb" />
                </Canvas>
            </div>

            

          <p>
            TARS stands for the <a href="https://tars.clarkson.edu/">Terascale All-Sensing Research Studio</a>! They do wonderful research in
            human-driven artificial intelligence using capture and analysis of dense multi-person interactions in online and real-world environments,
            terabytes of data, human robot interaction, additive manufacturing, biometric analysis, and Virtual Reality. It's a place where really
            creative technology works can be explored!
          </p>
          <p>
            I joined TARS as a freshman computer science undergraduate in my fall second semester at Clarkson University.
            Initially, I was exploring Virtual Reality (VR) with <a href="https://ieeexplore.ieee.org/author/37087137616">Robert Miller</a>, a PhD at the time.
            VR was fascinating to me as I've never had the chance to explore it before, and had an unbounded amount of ideas for how to use it to
            expand our perceptions. It was an expansion of my understanding of what I could perceive and explore, VR is sort of like this portal into an alternate Universe.
          </p>
          <p>
            Due to my curiosity, I eventually decided I needed to spearhead my own project, this was the birth of the VR Drones Project.
            So I got a team together at TARS with graduate and undergraduate students. With them, I started developing novel work on creating modular drone robotics.
            My idea was essentially to try to make cognitively intelligent flying robots that could communicate naturally with human beings,
            towards a method for two-way fluid communication and teleoperation of drones.
          </p>
          <p>
            This was inspired by the "<a href="https://theculture.fandom.com/wiki/Drone">Independent Drones</a>" that roamed around the future civilizations
            illustrated in The Culture novels. In The Hydrogen Sonata, there was an eccentric retired drone that built sand sculptures.
            I wonder if future robots will also have such whimsical characteristics embedded within them! We explored modularity in composition of the drones,
            modularity in their heterogeneous combination in midair as a swarm, battery swapping mechanisms, VR teleoperation of the drones, and biometrics of those controlling the drones.
          </p>
          <p>
            The main thesis behind modularity is to easily replace and repair broken components. Currently, TARS is working hard on automatic assembly with manipulator robots
            and repair of objects through learning their precise geometries with parametric additive manufacturing methods.
          </p>
          <p>
            I led the team, consisting of several undergraduates and graduate students, with myself acting as the lead for integrating the systems together, planning the roadmap,
            testing, fieldwork, procurement, design and fabrication of 3D printed components. I contributed most heavily to working on the control software to interface the drone with Python
            so we could programmatically operate the drone's functionalities, there were of course, many problems along the way, but that's where all the fun is! Before I left,
            I was working on a VR Teleoperation protocol for drones, so you could see from the point of view of a drone, and naturalistically control it!
          </p>
          <p>
            <strong>Motivation:</strong> One of the reasons for developing modular drone swarms is that it allows you to create a system of flying robots that can work together to create emergent behavior.
            The drones can then do behavior that they couldn’t do together, such as creating large complex structures on the ground in a small amount of time, or perhaps in space!
            The drones can share resources with each other to remain robust and repairable. To share resources across different drones requires synchronization of their behavior across space and time and precise programmatic control.
            If you add manipulator arms to these kinds of drones, they can even manipulate objects in the world around them. You can then create a virtual reality interface to allow you as a user to embody the behavior of the swarm or chain of connected drones.
          </p>
          <p>
            <strong>Design and Prototyping:</strong> We initially started with pancake drones that hooked into each other, but eventually moved towards box drones.
            We constantly iterated on new variants of drones. Lots of different iterations on the core idea around modularity.
            The key essence of modularity is that you can just easily replace one part with another as individual components are not special. Longer-term,
            you want to be able to construct these robots through the additive manufacturing of replacement parts, you’ll also want to use commodity hardware,
            so you have replaceable electronic building blocks for a drone swarm that might share physical resources such as energy, or computation.
          </p>
        </div>
      )}
    </div>
  );
};

export default TerascaleResearch;
