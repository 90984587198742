import React, { useRef, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { Text } from '@react-three/drei';
import { useNavigate } from 'react-router-dom';
import './styling/ThreeDBlogPostTile.css'; // Import the specific styles

function ThreeDBlogPostTile({ title, description, link }) {
  const navigate = useNavigate();
  const cubeRef = useRef();
  const textRef = useRef();
  const lightRef = useRef(); // Re-add the lightRef
  const { camera } = useThree();
  const [isHovered, setHovered] = useState(false);
  const [angularSpeed, setAngularSpeed] = useState(0.00);

  const onCubeClick = () => {
    navigate(link);
  };

  const rippleShader = {
    uniforms: {
      time: { value: 0.0 },
    },
    vertexShader: `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      uniform float time;
      varying vec2 vUv;
      void main() {
        float speed = 2.0;
        float amplitude = 0.1;
        vec2 distortedUV = vUv + vec2(sin(vUv.y * 10.0 + time * speed) * amplitude, 0.0);
        vec3 color = vec3(abs(sin(distortedUV.x)), abs(sin(distortedUV.y)), abs(cos(distortedUV.x * distortedUV.y)));
        gl_FragColor = vec4(color, 0.7);
      }
    `,
  };

  useFrame(({ clock }) => {
    // Rotate the cube based on angular speed
    if (cubeRef.current) {
      cubeRef.current.rotation.x += angularSpeed;
      cubeRef.current.rotation.y += angularSpeed;
  
      // Wobble effect, oscillating around the initial y position
      cubeRef.current.position.y = Math.sin(clock.elapsedTime * 2) * 0.1;

      //     // Update text position
      if (textRef.current && camera) {
        const { x, y, z } = cubeRef.current.position;
        textRef.current.position.set(x, y + 1.8, z + 0.6);
        textRef.current.lookAt(camera.position);
      }
      
      // Update shader time uniform for the ripple effect
      if (cubeRef.current.material && cubeRef.current.material.uniforms) {
        cubeRef.current.material.uniforms.time.value = clock.elapsedTime;
      }
    }
  
    // Hover effect to increase or decrease angular speed
    if (isHovered) {
      if (angularSpeed < 0.2) {
        setAngularSpeed(angularSpeed + 0.001);
      }
    } else {
      if (angularSpeed > 0.001) {
        setAngularSpeed(angularSpeed - 0.001);
      } else {
        setAngularSpeed(0);
      }
    }
  
    // Ensure text is facing the camera
    if (textRef.current && camera) {
      textRef.current.lookAt(camera.position);
    }
  });
  
  return (
    <group
      ref={cubeRef}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
    >
      <mesh onClick={onCubeClick}>
        <boxGeometry args={[2, 2, 2]} />
        <shaderMaterial
          attach="material"
          args={[rippleShader]}
          uniforms={rippleShader.uniforms}
          transparent={true}
          opacity={0.5}
        />
      </mesh>

      <group ref={textRef} position={[0, 1.5, 0]}>
        <Text
          fontSize={0.8}
          color="black"
          anchorX="center"
          anchorY="middle"
        >
          {title}
        </Text>
      </group>

      {/* Re-add the spinning spotlight */}
      <spotLight
        ref={lightRef}
        position={[0, 5, 0]}
        angle={0.5}
        penumbra={0.7}
        intensity={2}
        castShadow={true}
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
      />
    </group>
  );
}

export default ThreeDBlogPostTile;


