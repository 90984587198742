// BlogPost.js
import React from 'react';
import './styling/BlogPost.css'; // Make sure to create this CSS file

function BlogPost({ title, content, imageUrl }) {
  return (
    <div className="blog-post">
      <img src={imageUrl} alt={title} className="blog-image"/>
      <div className="blog-content">
        <h2 className="blog-title">{title}</h2>
        <p className="blog-paragraph">{content}</p>
      </div>
    </div>
  );
}

export default BlogPost;
