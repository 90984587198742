import React from 'react';
import { Canvas } from '@react-three/fiber';
import RotatingCoin from './RotatingCoin';
import './styling/Contact.css';

const contactInfo = [
  { text: 'Email', link: 'mailto:alif@halcyox.com', colorIndex: 0 },
  { text: 'GitHub', link: 'https://github.com/Caerii', colorIndex: 1 },
  { text: 'LinkedIn', link: 'https://linkedin.com/in/alif-jakir', colorIndex: 2 },
];

const Contact = () => (
  <div className="coin-container">
    {contactInfo.map(({ text, link, colorIndex }) => (
      <div key={link} className="coin">
        <Canvas>
          <RotatingCoin text={text} link={link} colorIndex={colorIndex} />
        </Canvas>
      </div>
    ))}
  </div>
);

export default Contact;
