// Portfolio.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import profileImage from './profile-image.jpg';
import Timeline from './Timeline';
import { Footnote, FootnoteSidebar } from './Footnote';
import './styling/Portfolio.css';
import TerascaleResearch from './portfolio-items/TerascaleResearch.js';
import GeorgiaStateREU from './portfolio-items/GeorgiaStateREU.js';
import VizcomAI from './portfolio-items/VizcomAI.js';

const Portfolio = () => {
  return (
    <div className="App">
      <header className="header">
        <Container className="main-container">
          <Row>
            <Col md={12} className="profile">
              <img src={profileImage} alt="Alif Jakir" className="profile-image" />
              <h1>Alif Jakir</h1>
              <p>Transdisciplinary Philosopher-Scientist & Generalist AI Engineer</p>
              <div className="contact">
                <Link to="mailto:alifnull@mit.edu">Send me an email</Link>
                <Link to="https://github.com/Caerii">Github</Link>
                <Link to="https://www.linkedin.com/in/alif-jakir">LinkedIn</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      <main>
        <Container>
          <section className="about portfolio-section">
          <div style={{ position: 'relative', padding: '0 0 0 0' }}>

            <h2>Summary</h2>
            <p>
              I'm a generalist computer scientist, AI researcher, engineer, and philosopher. Currently tinkering with blueprints for AGI. My research paradigm is driven largely by wanting to forge a symbiosis between artificial and organic systems. I have a deep curiosity regarding the nature of complex systems, evolution across different scales and substrates, cognition. I am interested in augmenting collective sense-making. I have experience leading teams, and prefer empirical, data-driven approaches to research and development. 
              <Footnote number={1} text="This approach allows for systematic and repeatable processes, increasing reliability and objectivity." />
            </p>
            <FootnoteSidebar number={1} text="This is the footnote content" />
            </div>

          </section>

          <section className="timeline portfolio-section">
            <Timeline />  
          </section>

          <section className="experience portfolio-section">
            <h2>Experience</h2>
            <div>
          
              <h3>MIT Media Lab | Neurafutures | Research Programmer</h3>
              <p>Sept 2022 - Present | Cambridge, MA</p>
              
              <ul>
                <li>Designed and led the implementation of a 3D animation pipeline for a touchless holographic interface (Looking Glass display) using EEG control. Project exhibited at MIT Museum. Currently developing open source designs for a Mixed Reality Brain Computer Interface. Researching interfaces for Human Augmentation.</li>
                <li>Worked under Dr. Nataliya Kosmyna, Fluid Interfaces group. <Footnote number={2} text="Dr. Kosmyna is known for her work in brain-computer interfaces and immersive technologies." /></li>
                <FootnoteSidebar number={2} text="This is the footnote content" />

              </ul>

            </div>

            <div>
              <h3>Stealth Startup | Co-Chief Executive Officer</h3>
              <p>Sept 2023 - Present | Cambridge, MA</p>
              <ul>
                <li>Co-leading team on systems engineering design, multiphysics simulation, metamaterial design, programmable metasurfaces, and novel additive manufacturing techniques for applications in Synthetic Biology.</li>
                <li>Skills: Engineering Physics, Materials Science, Modeling and Simulation, Nanotechnology. <Footnote number={3} text="These fields combine to create cutting-edge technologies in synthetic biology and materials science." /></li>
              </ul>
            </div>
            <FootnoteSidebar number={3} text="Simulation." />

            <div>
              <h3>Cybercastellum | Software Engineer</h3>
              <p>Sept 2023 - Dec 2023 | Virtual</p>
              <ul>
                <li>Full stack software engineering, cybersecurity, and data engineering for automation on NY State document backlogs.</li>
              </ul>
            </div>
            <VizcomAI/>
            <div>
              <h3>Clarkson Google Developer Student Club | Lead</h3>
              <p>Jun 2021 – May 2023 | Potsdam, NY</p>
              <ul>
                <li>Coordinated a technical core team, workshops & lectures on emerging tech, brought speakers from Google. AI pair-programming, XR, web & cloud native development. Collaborated with Clarkson Open Source community. <Footnote number={5} text="The club focused on enhancing technical skills and fostering a collaborative learning environment." /></li>
              </ul>
            </div>
            <div>
              <h3>NASA RASC-AL Competition | Lead</h3>
              <p>Sep 2021 - May 2022 | Potsdam, NY</p>
              <ul>
                <li>Led large international collaboration for scalable safe autonomous self-charging nuclear modular storage infrastructure system for Lunar South Pole missions. Adhered to NASA Systems Engineering principles. <Footnote number={6} text="NASA Systems Engineering principles ensure rigorous and structured project development." /></li>
              </ul>
            </div>
            <div>
              <h3>New York State Government | Political Intern</h3>
              <p>Aug 2018 - Nov 2018 | Long Island, NY</p>
              <ul>
                <li>Spoke with voters in the field and researched policy to execute an action plan, contributing to the election victory and flipping the New York State Senate legislature.</li>
              </ul>
            </div>
          </section>

          <section className="research portfolio-section">
            <h2>Research</h2>
            <TerascaleResearch />
            <GeorgiaStateREU />
            <div>
              <h3>Harvard | Precipice Fellow @ Harvard EA | AI Incubator, FabulousFables @ HUMIC.ai</h3>
              <p>Sep 2023 - Present | Cambridge, MA</p>
              <ul>
                <li>Analysis of existential risks from technological growth, policy considerations, future forecasting with Precipice Fellows. <Footnote number={7} text="The Precipice Fellows focus on addressing long-term global risks and future forecasting." /></li>
                <li>Leading team-based development, setup infrastructure on AWS for foundation and generative AI model hosting, training, collaboration on software architecture, UI/UX. <Footnote number={8} text="AWS provides a scalable cloud platform for AI model development and deployment." /></li>
              </ul>
            </div>
            <div>
              <h3>MIT Center for Collective Intelligence (CCI Lab) | Research Assistant</h3>
              <p>Jan 2023 - Present | Cambridge, MA</p>
              <ul>
                <li>AI tools for empirical improvement of Human-machine teams with swarm multi-agent AI interfaces. "Chain of Agents". <Footnote number={9} text="The Chain of Agents project aims to enhance team collaboration using multi-agent AI systems." /></li>
              </ul>
            </div>
            <div>
              <h3>MIT Immersion Lab @ MIT Nano | Research Assistant</h3>
              <p>Sep 2023 - Present | Cambridge, MA</p>
              <ul>
                <li>XRAgents architecture framework for interaction with immersive 3D AI characters, applied to virtual training, therapeutics. <Footnote number={10} text="XRAgents are designed to facilitate interaction within immersive 3D environments." /></li>
              </ul>
            </div>
            <div>
              <h3>DTU - Technical University of Denmark | Undergraduate Student Researcher</h3>
              <p>Jun 2023 - Jul 2023 | Kongens Lyngby, Denmark</p>
              <ul>
                <li>Completed courses in Computational Hyperspectral Imaging, Computer Vision, and Optical Planar Waveguide Fabrication.</li>
                <li>Led a team to create a solution for Computational Low Light Image Enhancement using Generative Adversarial Networks (GANs). Designed optical ring resonators and waveguides in L-edit and conducted simulations in Ansys Lumerical for silicon fabrication processing in cleanroom. <Footnote number={11} text="This research focused on improving low-light imaging using advanced computational techniques." /></li>
              </ul>
            </div>
          </section>

          <section className="education portfolio-section">
            <h2>Education</h2>
            <div>
              <h3>Clarkson University</h3>
              <p>B.S. in Computer Science | B.S. in Business Intelligence & Data Analytics | Minors in Robotics, Math</p>
              <p>May 2023 | Potsdam, NY</p>
            </div>
          </section>

          <section className="skills portfolio-section">
            <h2>Skills</h2>
            <p>C/C++, Python, PyTorch, GPT-4, WebGL, Java, C#, JavaScript, R, Matlab, SQL, Flask, Maya, Excel, Tableau, Scikit-learn, ML-Agents, Matplotlib, VirtualBox, Blender, Adobe Suite, Git/VCS, ROS Python, Omniverse, Solidworks, Unity3D, GCP, AWS, BCI, VR, Arduino, TCP/IP, Unix, Windows, Mac, NLP, NLTK, OpenCV</p>
          </section>

          <section className="projects portfolio-section">
            <h2>Projects</h2>
            <div>
              <h3><Link to="https://www.xr.augmentationlab.org/xr-agents">XRAgents</Link></h3>
              <p>In development open source framework for high fidelity 3D Agents in XR worlds.</p>
            </div>
            <div>
              <h3><Link to="https://devpost.com/software/moodspace">Moodspace</Link></h3>
              <p>MIT Reality Hack 2022 | Emotionally aware meta-conversations with Hololens and EEG. Real-time biometric data-driven XR.</p>
            </div>
            <div>
              <h3><Link to="https://devpost.com/software/lifeinbetweenxr">LifeInBetweenXR</Link></h3>
              <p>MIT Reality Hack 2023 (semifinalist) | Interconnecting locals with their environments. Mobile + 3D Building Mesh Maps.</p>
            </div>
            <div>
              <h3><Link to="https://devpost.com/software/echo-101">EmpathyEase</Link></h3>
              <p>MIT Reality Hack 2024 | AI in mixed reality, mediating conflicts between people, emphasizing nonviolent communication.</p>
            </div>
            <div>
              <h3><Link to="https://www.youtube.com/c/ajphilanthropist/videos">AI YouTube Philosophy Channel for Halcyox</Link></h3>
              <p>Created software to programmatically generate YouTube videos that received in total 6.6m impressions, 895,000 views, 47,500 watch hours, 6,852 subscribers, and over $3,000 in ad revenue. Content explores narratives, philosophy, humor, existentialism, zeitgeist, the future of civilization, and contemporary topics. <Footnote number={12} text="This channel combines AI with philosophical content to engage a broad audience." /></p>
            </div>
          </section>

        </Container>
      </main>
      <footer className="footer">
        <Container>
          <p>&copy; {new Date().getFullYear()} Alif Jakir. All rights reserved.</p>
        </Container>
      </footer>
    </div>
  );
};

export default Portfolio;


// Portfolio.js
// import React, { useState } from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import profileImage from './profile-image.jpg';
// import { Footnote, FootnoteSidebar } from './Footnote';
// import './styling/Portfolio.css';

// const Portfolio = () => {
//   const [activeFootnote, setActiveFootnote] = useState(null);

//   return (
//     <div className="App">
//       <header className="header">
//         <Container className="main-container">
//           <Row>
//             <Col md={12} className="profile">
//               <img src={profileImage} alt="Alif Jakir" className="profile-image" />
//               <h1>Alif Jakir</h1>
//               <p>Transdisciplinary Philosopher-Scientist & Generalist AI Engineer</p>
//               <div className="contact">
//                 <Link to="mailto:alifnull@mit.edu">Send me an email</Link>
//                 <Link to="https://github.com/Caerii">Github</Link>
//                 <Link to="https://www.linkedin.com/in/alif-jakir">LinkedIn</Link>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </header>
//       <main>
//         <Container>
//           <Row>
//             <Col md={8} className="main-content">
//               {/* Main content */}
//               <p>Here is some text with a footnote.<Footnote number={1} text="This is a footnote explanation." setActiveFootnote={setActiveFootnote} /></p>
//               <p>Another sentence with another footnote.<Footnote number={2} text="This is another footnote explanation." setActiveFootnote={setActiveFootnote} /></p>
              
//               <div>
//                <h3>New York State Government | Political Intern</h3>
//                <p>Aug 2018 - Nov 2018 | Long Island, NY</p>
//                <ul>
//                  <li>Spoke with voters in the field and researched policy to execute an action plan, contributing to the election victory and flipping the New York State Senate legislature.</li>
//                </ul>
//              </div>
//              <div>
//                <h3>New York State Government | Political Intern</h3>
//                <p>Aug 2018 - Nov 2018 | Long Island, NY</p>
//                <ul>
//                  <li>Spoke with voters in the field and researched policy to execute an action plan, contributing to the election victory and flipping the New York State Senate legislature.</li>
//                </ul>
//              </div>
//               <p>Here is some more text with a footnote.<Footnote number={3} text="This is yet another footnote explanation." setActiveFootnote={setActiveFootnote} /></p>
//             </Col>
//             <Col md={4} className="sidebar">
//               {/* Sidebar for sidenotes */}
//               <FootnoteSidebar number={1} text="Details for footnote 1" activeFootnote={activeFootnote} setActiveFootnote={setActiveFootnote} />
//               <FootnoteSidebar number={2} text="Details for footnote 2" activeFootnote={activeFootnote} setActiveFootnote={setActiveFootnote} />
//               <FootnoteSidebar number={3} text="Details for footnote 3" activeFootnote={activeFootnote} setActiveFootnote={setActiveFootnote} />
//             </Col>
//           </Row>
//         </Container>
//       </main>
//       <footer className="footer">
//         <Container>
//           <p>&copy; {new Date().getFullYear()} Alif Jakir. All rights reserved.</p>
//         </Container>
//       </footer>
//     </div>
//   );
// };

// export default Portfolio;


