import React, { useState, useEffect, useRef } from 'react';
import './styling/Footnote.css';

export const Footnote = ({ number, text }) => {
  const [isHighlighted, setIsHighlighted] = useState(false);
  const footnoteRef = useRef(null);
  const sidebarRef = useRef(null);

  useEffect(() => {
    sidebarRef.current = document.getElementById(`sidebar-footnote-${number}`);
  }, [number]);

  const handleMouseEnter = () => {
    setIsHighlighted(true);
    if (sidebarRef.current) {
      sidebarRef.current.classList.add('highlight-border');
    }
  };

  const handleMouseLeave = () => {
    setIsHighlighted(false);
    if (sidebarRef.current) {
      sidebarRef.current.classList.remove('highlight-border');
    }
  };

  return (
    <span 
      className={`footnote ${isHighlighted ? 'highlight' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={footnoteRef}
    >
      <sup className="footnote-number">{number}</sup>
    </span>
  );
};

export const FootnoteSidebar = ({ number, text }) => {
  const [isHighlighted, setIsHighlighted] = useState(false);
  const footnoteRef = useRef(null);

  useEffect(() => {
    footnoteRef.current = document.querySelector(`.footnote-number:nth-of-type(${number})`);
  }, [number]);

  const handleMouseEnter = () => {
    setIsHighlighted(true);
    if (footnoteRef.current) {
      footnoteRef.current.parentElement.classList.add('highlight');
    }
  };

  const handleMouseLeave = () => {
    setIsHighlighted(false);
    if (footnoteRef.current) {
      footnoteRef.current.parentElement.classList.remove('highlight');
    }
  };

  return (
    <div 
      className={`footnote-sidebar ${isHighlighted ? 'highlight-border' : ''}`}
      id={`sidebar-footnote-${number}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="footnote-number-sidebar">{number}</div>
      <div className="footnote-content">{text}</div>
    </div>
  );
};

// import React from 'react';
// import './styling/Footnote.css';

// // Footnote component
// export const Footnote = ({ number, text, setActiveFootnote, activeFootnote }) => {
//   const handleMouseEnter = () => setActiveFootnote(number);
//   const handleMouseLeave = () => setActiveFootnote(null);

//   return (
//     <span 
//       className={`footnote ${activeFootnote === number ? 'highlight' : ''}`}
//       onMouseEnter={handleMouseEnter}
//       onMouseLeave={handleMouseLeave}
//     >
//       <sup className="footnote-number">{number}</sup>
//       {/* Custom Tooltip */}
//       <span className="tooltip-text">{text}</span>
//     </span>
//   );
// };

// // FootnoteSidebar component
// export const FootnoteSidebar = ({ number, text, setActiveFootnote, activeFootnote }) => {
//   const handleMouseEnter = () => setActiveFootnote(number);
//   const handleMouseLeave = () => setActiveFootnote(null);

//   return (
//     <div 
//       className={`footnote-sidebar ${activeFootnote === number ? 'highlight' : ''}`}
//       onMouseEnter={handleMouseEnter}
//       onMouseLeave={handleMouseLeave}
//     >
//       <div className="footnote-number-sidebar">{number}</div>
//       <div className="footnote-content">{text}</div>
//     </div>
//   );
// };



