import { useRef, useEffect } from 'react';
import { useLoader, useFrame, extend, useThree } from '@react-three/fiber';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as THREE from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';

extend({ OrbitControls });

function AlifCape({ modelPath, scale = 0.05 }) {
  const modelRef = useRef();
  const controlsRef = useRef();
  const fbx = useLoader(FBXLoader, modelPath);

  const { camera, gl, scene } = useThree();

  useEffect(() => {
    new RGBELoader().load('/path/to/your/hdr/image.hdr', (texture) => {
      texture.mapping = THREE.EquirectangularReflectionMapping;
      scene.environment = texture;
      scene.background = texture;
    });

    if (fbx) {
      // Center the model
      const box = new THREE.Box3().setFromObject(fbx);
      const center = box.getCenter(new THREE.Vector3());

      // Create a pivot to rotate the model around its center
      const pivot = new THREE.Object3D();
      pivot.position.set(center.x, center.y, center.z);
      pivot.add(fbx);
      
      // Adjust the position of the model relative to the pivot
      fbx.position.set(-center.x, -center.y, -center.z);

      // Scale the pivot group
      pivot.scale.set(scale, scale, scale);

      fbx.traverse((child) => {
        if (child.isMesh) {
          // Ensure the existing materials are preserved
          child.material.needsUpdate = true;
        }
      });

      modelRef.current = pivot;
      scene.add(pivot);
    }
  }, [fbx, scene, scale]);

  // Based on clock, rotate the model
  useFrame(({ clock }) => {
    const time = clock.getElapsedTime();
    if (modelRef.current) {
      // Adjust the speed and damping factor as needed
      const speed = 10.9; // Initial speed
      const damping = 2.9; // Damping factor
  
      // Calculate the dampened rotation speed
      const dampenedSpeed = speed * Math.exp(-damping * time);
  
      // Apply the dampened rotation
      modelRef.current.rotation.y += dampenedSpeed;
    }
  });
  

  useFrame(() => {
    if (controlsRef.current) {
      controlsRef.current.update();
    }
  });

  return (
    <>
      <ambientLight intensity={0.4} />
      <directionalLight position={[10, 10, 10]} intensity={10.5} />
      {modelRef.current && <primitive object={modelRef.current} />}
      <orbitControls ref={controlsRef} args={[camera, gl.domElement]} enableZoom={true} enablePan={true} enableRotate={true} enableDamping={true} dampingFactor={0.01} />
    </>
  );
}

export default AlifCape;
